import { HTTP_STATUS_CODES } from '@emobg/web-utils';
import { external } from '@emobg/web-api-client';

import get from 'lodash/get';
import map from 'lodash/map';

import languageService from '@/services/Language';
import { useAuth } from '@/composable/Api/Auth/auth';
import { isItalianUserOrCompany } from '@Account/SignUp/helpers';
import externalApi from '@/vue/api/external/app.external-api';

import { buildCreateAccountRequestParams, createError } from '@/helpers/actionsHelpers';
import { MUTATIONS } from './mutations';

const { idpUuid } = useAuth();

export const ACTIONS = {
  setLegalDocuments: 'setLegalDocuments',
  createUser: 'createUser',
  B2BLinkToExistingAccount: 'B2BLinkToExistingAccount',
  B2BCreateEmployeeFromInvitation: 'B2BCreateEmployeeFromInvitation',
  B2BCreateEmployee: 'B2BCreateEmployee',
  checkInvitationB2B: 'checkInvitationB2B',
  updateItalianFiscalCode: 'updateItalianFiscalCode',
};

export default {
  async [ACTIONS.setLegalDocuments]({ state, commit }, locale) {
    const operatorUuid = get(state, 'b2b.company.cs_operator_uuid')
      || get(state, 'b2b.company.csOperatorUuid');
    const defaultCsOperatorUuid = get(state, 'data.country.default_cs_operator_uuid')
      || get(state, 'data.country.defaultCsOperatorUuid');
    const uuid = state.b2b.isEmployee ? operatorUuid : defaultCsOperatorUuid;

    try {
      const defaultLanguage = languageService.getLanguageCodeFromLocale(languageService.defaultLang);
      const language = languageService.getLanguageCodeFromLocale(locale) || defaultLanguage;

      const { documents = [] } = (await external.userCompanyCSOperator.getLegalDocuments(uuid, 'v4', language) || {});

      const documentsUuids = map(documents, 'uuid');
      commit(MUTATIONS.legalDocumentsAccepted, documentsUuids);
      commit(MUTATIONS.legalDocuments, documents);
    } catch (error) {
      createError(state.notifyErrorCallback, error);
    }
  },

  async [ACTIONS.createUser]({ state, getters, commit }, payload) {
    try {
      const { countryCode, addressCountryCode } = getters;
      const params = buildCreateAccountRequestParams(
        state, payload, true, undefined, isItalianUserOrCompany(countryCode, addressCountryCode),
      );

      const response = await external.userCompanyUserAccount.postCreateUser(params);

      commit(MUTATIONS.accountCreated, response);
    } catch (error) {
      commit(MUTATIONS.accountCreated, null);
      createError(state.notifyErrorCallback, error);
    }
  },

  // B2B
  async [ACTIONS.B2BLinkToExistingAccount]({ state, commit }) {
    const email = state.b2b.invitation.invite.email || state.b2b.link.email;

    await externalApi.linkPersonalAccountToB2BUsingInvitation(
      idpUuid,
      state.b2b.invitationToken,
      { email },
    ).then(response => {
      commit(MUTATIONS.b2bResponse, response.data);
    }).catch(error => {
      createError(state.notifyErrorCallback, error);
      commit(MUTATIONS.b2bResponse, null);
    });
  },

  async [ACTIONS.B2BCreateEmployeeFromInvitation]({ state, getters, commit }, payload) {
    const { countryCode, addressCountryCode } = getters;
    const isCorporateUser = get(state, 'b2b.isCorporateUser');
    const params = buildCreateAccountRequestParams(
      state, payload, false, isCorporateUser, isItalianUserOrCompany(countryCode, addressCountryCode),
    );

    const version = isCorporateUser ? 'v2' : 'v1';

    await external.userCompany
      .postEmployeeFromInvitation(params, state.b2b.invitationToken, version)
      .then(response => {
        commit(MUTATIONS.accountCreated, response);
      })
      .catch(error => {
        commit(MUTATIONS.accountCreated, null);
        createError(state.notifyErrorCallback, error);
      });
  },

  async [ACTIONS.B2BCreateEmployee]({ state, getters, commit }, payload) {
    const { countryCode, addressCountryCode } = getters;
    const isCorporateUser = get(state, 'b2b.isCorporateUser');
    const params = buildCreateAccountRequestParams(
      state, payload, false, isCorporateUser, isItalianUserOrCompany(countryCode, addressCountryCode),
    );

    const version = isCorporateUser ? 'v2' : 'v1';

    await external.userCompany.postEmployee(params, version).then(response => {
      commit(MUTATIONS.accountCreated, response);
    }).catch(error => {
      commit(MUTATIONS.accountCreated, null);
      createError(state.notifyErrorCallback, error);
    });
  },

  async [ACTIONS.checkInvitationB2B]({ state, commit }) {
    const { invitationToken, invitationUUID } = state.b2b;
    try {
      const response = await externalApi.validateInvitation(invitationToken, invitationUUID);
      if (response.data.valid_invite) {
        commit(MUTATIONS.setB2BData, {
          isCorporateUser: get(response, 'data.company.has_dedicated_fleet') && get(response, 'data.company.owns_employee_data'),
          company: get(response, 'data.company'),
          invitation: get(response, 'data'),
        });
      }
    } catch (error) {
      const status = get(error, 'response.status');
      commit(MUTATIONS.validInvite, status === HTTP_STATUS_CODES.conflict);
      if (status !== HTTP_STATUS_CODES.conflict) {
        createError(state.notifyErrorCallback, get(error, 'response.data'));
      }
    }
  },

  [ACTIONS.updateItalianFiscalCode]({ commit }, italianFiscalCode) {
    commit(MUTATIONS.setItalianFiscalCode, italianFiscalCode);
  },
};
