import getInitialState from './state';

export const MUTATIONS = {
  doneStep: 'doneStep',
  undoStep: 'undoStep',
  setEmail: 'setEmail',
  setPassword: 'setPassword',
  setData: 'setData',
  setB2BData: 'setB2BData',
  updateStep: 'updateStep',
  notifyErrorCallback: 'notifyErrorCallback',
  country: 'country',
  title: 'title',
  firstName: 'firstName',
  lastName: 'lastName',
  lastStep: 'lastStep',
  resetUser: 'resetUser',
  birthDate: 'birthDate',
  taxNumber: 'taxNumber',
  idNumber: 'idNumber',
  address: 'address',
  addressDetails: 'addressDetails',
  setItalianFiscalCode: 'setItalianFiscalCode',
  phone: 'phone',
  legalDocumentsAccepted: 'legalDocumentsAccepted',
  receiveNews: 'receiveNews',
  promoCode: 'promoCode',
  ubeeqoCountries: 'ubeeqoCountries',
  ubeeqoTitles: 'ubeeqoTitles',
  legalDocuments: 'legalDocuments',
  accountCreated: 'accountCreated',
  invitation: 'invitation',
  invitationToken: 'invitationToken',
  invitationUUID: 'invitationUUID',
  validInvite: 'validInvite',
  linkEmail: 'linkEmail',
  linkPhone: 'linkPhone',
  b2bResponse: 'b2bResponse',
  isEmployee: 'isEmployee',
  trackingId: 'trackingId',
  b2bCompany: 'b2bCompany',
  setIsPhoneNumberValid: 'setIsPhoneNumberValid',
  setForceErrorOnWrongPromoCode: 'setForceErrorOnWrongPromoCode',
};

export default {
  // STEPS
  [MUTATIONS.doneStep](state, value) {
    state.done[value] = true;
  },
  [MUTATIONS.undoStep](state, value) {
    Object.keys(state.done).forEach(step => {
      if (step >= value) {
        state.done[step] = false;
      }
    });
  },
  [MUTATIONS.setEmail](state, value) {
    state.data.email = value;
  },
  [MUTATIONS.setPassword](state, value) {
    state.data.password = value;
  },
  [MUTATIONS.setData](state, value) {
    state.data = {
      ...state.data,
      ...value,
    };
  },
  [MUTATIONS.setB2BData](state, value) {
    state.b2b = {
      ...state.b2b,
      ...value,
    };
  },
  [MUTATIONS.updateStep](state, value) {
    state.currentStep = value;
  },
  [MUTATIONS.notifyErrorCallback](state, notifyError) {
    state.notifyErrorCallback = notifyError;
  },
  [MUTATIONS.country](state, country) {
    state.data.country = country;
  },
  [MUTATIONS.title](state, title) {
    state.data.title = title;
  },
  [MUTATIONS.firstName](state, firstName) {
    state.data.firstName = firstName;
  },
  [MUTATIONS.lastName](state, lastName) {
    state.data.lastName = lastName;
  },
  [MUTATIONS.lastStep](state, lastStep) {
    state.lastStep = lastStep;
  },
  [MUTATIONS.resetUser](state) {
    return Object.assign(state, getInitialState());
  },
  [MUTATIONS.birthDate](state, value) {
    state.data.birthDate = value;
  },
  [MUTATIONS.taxNumber](state, value) {
    state.data.taxNumber = value.length > 2 ? value : '';
  },
  [MUTATIONS.idNumber](state, idNumber) {
    state.data.idNumber = idNumber;
  },
  [MUTATIONS.address](state, address) {
    state.data.address = address;
  },
  [MUTATIONS.addressDetails](state, addressDetails) {
    state.data.addressDetails = addressDetails;
  },
  [MUTATIONS.setItalianFiscalCode](state, italianFiscalCode) {
    state.data.italianFiscalCode = italianFiscalCode;
  },
  [MUTATIONS.phone](state, phone) {
    state.data.phone = phone;
  },
  [MUTATIONS.legalDocumentsAccepted](state, accepted) {
    state.data.legalDocumentsAccepted = accepted;
  },
  [MUTATIONS.receiveNews](state, receiveNews) {
    state.data.receiveNews = receiveNews;
  },
  [MUTATIONS.promoCode](state, promoCode) {
    state.data.promoCode = promoCode;
  },
  // INFO
  [MUTATIONS.ubeeqoCountries](state, countries) {
    state.info.countries = countries;
  },
  [MUTATIONS.ubeeqoTitles](state, titles) {
    state.info.titles = titles;
  },
  [MUTATIONS.legalDocuments](state, documents) {
    state.info.legalDocuments = documents;
  },
  [MUTATIONS.accountCreated](state, accountCreated) {
    state.info.accountCreated = accountCreated;
  },
  // B2B
  [MUTATIONS.invitation](state, b2b) {
    state.b2b.invitation = b2b;
  },
  [MUTATIONS.invitationToken](state, invitationToken) {
    state.b2b.invitationToken = invitationToken;
  },
  [MUTATIONS.invitationUUID](state, invitationUUID) {
    state.b2b.invitationUUID = invitationUUID;
  },
  [MUTATIONS.validInvite](state, validInvite) {
    state.b2b.invitation.valid_invite = validInvite;
  },
  [MUTATIONS.linkEmail](state, linkEmail) {
    state.b2b.link.email = linkEmail;
  },
  [MUTATIONS.linkPhone](state, linkPhone) {
    state.b2b.link.phone = linkPhone;
  },
  [MUTATIONS.b2bResponse](state, response) {
    state.b2b.response = response;
  },
  [MUTATIONS.isEmployee](state, isEmployee) {
    state.b2b.isEmployee = isEmployee;
  },
  [MUTATIONS.trackingId](state, trackingId) {
    state.data.tracking_id = trackingId;
  },
  [MUTATIONS.b2bCompany](state, company) {
    state.b2b.company = company;
  },
  [MUTATIONS.setIsPhoneNumberValid](state, isValid) {
    state.validation.isPhoneNumberValid = isValid;
  },
  [MUTATIONS.setForceErrorOnWrongPromoCode](state, isValid) {
    state.validation.forceErrorOnWrongPromoCode = isValid;
  },
};
