import getInitialState from './_state';

export const TYPE = {
  SET_PAYMENT_METHOD_COLLECTION: 'SET_PAYMENT_METHOD_COLLECTION',

  RESET_STATE: 'RESET_STATE',
};

export default {
  [TYPE.SET_PAYMENT_METHOD_COLLECTION](state, paymentMethodCollection) {
    return Object.assign(state, {
      paymentMethodCollection,
    });
  },

  [TYPE.RESET_STATE](state) {
    return Object.assign(state, getInitialState());
  },
};
