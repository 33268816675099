import { external } from '@emobg/web-api-client';

import { errorNotification } from '@/handlers/errorHandler.const';
import { SERVICES } from '@/constants/services.const';
import ALERT_TYPES from '@/components/Alert/alertTypes';

import languageService from '@/services/Language';

const locale = languageService.getLanguage();
const language = languageService.getLanguageCodeFromLocale(locale);

const query = {
  service: SERVICES.carSharing,
  language,
  internal_testing: 0,
};

const actions = {
  getCountriesByService({ commit }) {
    external.app.getCountriesByService(query).then(countries => {
      commit('countries', countries);
    });
  },
  getUbeeqoTitles({ commit }) {
    external.userCompanyAccount.getUbeeqoTitles({ language }).then(response => {
      commit('titles', response.data);
    });
  },
  createError({ state }, error) {
    const { text } = errorNotification(error);

    state.instance.create({
      delayToClose: false,
      text,
      textAction: 'Close',
      type: ALERT_TYPES.danger,
    });
  },
};
export default actions;
