import { TYPE } from './_mutations';

export default {
  addNotification(context, notification) {
    const notificationsList = context.getters.getNotificationList;

    if (!notificationsList.includes(notification)) {
      context.commit(TYPE.CREATE_NOTIFICATION, notification);
    }
  },

  deleteNotification(context, notification) {
    context.commit(TYPE.DELETE_NOTIFICATION, notification);
  },

  deleteAllNotifications(context) {
    context.commit(TYPE.DELETE_ALL_NOTIFICATIONS);
  },
};
