import get from 'lodash/get';
import { external } from '@emobg/web-api-client';
import { LOG_TYPE, logger } from '@emobg/web-utils';
import Api from '@/vue/api/backoffice';
import UserData from '@Account/legacy/UserData';

import { TYPE } from './_mutations';

export const ACTIONS = {
  SET_USER_DATA: 'setUserData',
  fetchUserReferralInfo: 'fetchUserReferralInfo',
  FETCH_USER_DATA: 'fetchUserData',
  sendReferralInvitations: 'sendReferralInvitations',
  UPDATE_DIRECT_MARKETING: 'updateDirectMarketing',
  UPDATE_USER_PERSONAL_DATA: 'updateUserPersonalData',
};

// TODO Remove object-values [https://europcarmobility.atlassian.net/browse/CF-126]

export default {
  [ACTIONS.SET_USER_DATA]({ commit }, data) {
    let user = data;

    if (data.constructor.name !== UserData.name) {
      user = new UserData(data);
    }

    commit(TYPE.SET_AUTHENTICATED_USER, user);
  },

  setUserGeolocation(context, geolocation) {
    context.commit(TYPE.SET_GEOLOCATION, geolocation);
  },

  resetUserDataStore(context) {
    context.commit(TYPE.RESET_STATE);
  },

  async [ACTIONS.fetchUserReferralInfo]({ state, commit }) {
    const response = await external.userCompanyUserPromotions.getReferralInfo(state.userData.uuid);

    commit(TYPE.setReferralInfo, response);
  },

  // This method will be used in the /refactor folder
  // to update the data
  async [ACTIONS.FETCH_USER_DATA]({ dispatch }) {
    try {
      const response = await Api.userCompany.getUserDetail();

      dispatch(ACTIONS.SET_USER_DATA, response);

      return response;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },

  [ACTIONS.sendReferralInvitations]({ state }, emails) {
    return external.userCompanyUserPromotions.postInviteAFriend(state.userData.uuid, emails);
  },

  async [ACTIONS.UPDATE_DIRECT_MARKETING]() {
    try {
      return await external.userCompanyUserAccount.putUpdateMarketing();
    } catch (error) {
      logger.message(get(error, 'response.data.message', error.message) || this.$t('notifications.whooops'), LOG_TYPE.error);
      return {};
    }
  },

  async [ACTIONS.UPDATE_USER_PERSONAL_DATA](_, { userUuid, data }) {
    try {
      return await external.userCompanyUserAccount.putUserPersonalData(
        userUuid,
        data,
      );
    } catch (error) {
      logger.message(get(error, 'response.data.message', error.message) || this.$t('notifications.whooops'), LOG_TYPE.error);
      return {};
    }
  },
};
