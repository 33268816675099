import findIndex from 'lodash/findIndex';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import xor from 'lodash/xor';

import { createCustomStore, withScopes } from '@emobg/web-utils';
import { external } from '@emobg/web-api-client';

export const NAMESPACE = 'Invoices';

const SCOPES = {
  invoices: 'invoices',
  invoicesPdf: 'invoicesPdf',
};

export const GETTERS = {
  invoicesSelected: 'invoicesSelected',
  invoicesDownloading: 'invoicesDownloading',
  rawInvoicesPdf: 'rawInvoicesPdf',
};

const MUTATIONS = {
  toggleInvoiceAsSelected: 'toggleInvoiceAsSelected',
  unselectInvoices: 'unselectInvoices',
  setInvoicesAsDownloading: 'setInvoicesAsDownloading',
  unsetInvoicesAsDownloading: 'unsetInvoicesAsDownloading',
};

export const ACTIONS = {
  fetchInvoices: 'fetchInvoices',
  toggleInvoiceSelection: 'toggleInvoiceSelection',
  unselectAllInvoices: 'unselectAllInvoices',
  downloadInvoices: 'downloadInvoices',
};

const getInvoicesSelected = state => get(state, `${SCOPES.invoices}.data.invoicesSelected`, []);
const getInvoicesDownloading = state => get(state, `${SCOPES.invoices}.data.invoicesDownloading`, []);

const Invoices = createCustomStore(({ runAsyncFlow }) => withScopes(SCOPES, ({
  getters: {
    [GETTERS.invoicesSelected]: getInvoicesSelected,
    [GETTERS.invoicesDownloading]: getInvoicesDownloading,

    [GETTERS.rawInvoicesPdf]: state => get(state, `${SCOPES.invoicesPdf}.data`),
  },

  mutations: {
    [MUTATIONS.toggleInvoiceAsSelected](state, invoiceUuid) {
      state[SCOPES.invoices].data = {
        ...state[SCOPES.invoices].data,
        invoicesSelected: xor(getInvoicesSelected(state), [invoiceUuid]),
      };
    },

    [MUTATIONS.unselectInvoices](state) {
      state[SCOPES.invoices].data = {
        ...state[SCOPES.invoices].data,
        invoicesSelected: [],
      };
    },

    [MUTATIONS.setInvoicesAsDownloading](state, invoicesUuid) {
      state[SCOPES.invoices].data = {
        ...state[SCOPES.invoices].data,
        invoicesDownloading: [...getInvoicesDownloading(state), ...invoicesUuid],
      };
    },

    // state...invoicesDownloading can hold duplicate invoice uuids,
    // ... because the user can do more than one request on the same invoice/time.
    [MUTATIONS.unsetInvoicesAsDownloading](state, invoicesUuid) {
      forEach(invoicesUuid, invoiceUuid => {
        const index = findIndex(getInvoicesDownloading(state), uuid => uuid === invoiceUuid);

        state[SCOPES.invoices].data.invoicesDownloading.splice(index, 1);
      });
    },
  },

  actions: {
    async [ACTIONS.fetchInvoices]({ commit }, userUuid) {
      await runAsyncFlow(commit, {
        request: external.invoicing.getUserInvoices,
        params: [userUuid],
        scope: SCOPES.invoices,
      });
    },

    [ACTIONS.toggleInvoiceSelection]({ commit }, invoiceUuid) {
      commit('toggleInvoiceAsSelected', invoiceUuid);
    },

    [ACTIONS.unselectAllInvoices]({ commit }) {
      commit('unselectInvoices');
    },

    async [ACTIONS.downloadInvoices]({ commit }, invoicesUuid) {
      commit('setInvoicesAsDownloading', invoicesUuid);

      await runAsyncFlow(commit, {
        request: external.invoicing.requestDownloadInvoices,
        params: [invoicesUuid],
        scope: SCOPES.invoicesPdf,
      });

      commit('unsetInvoicesAsDownloading', invoicesUuid);
    },
  },
})));

export default Invoices;
