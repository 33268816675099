export default {
  getCompanyAdminPaymentMethodCollection(state) {
    return state.companyAdminPaymentMethodCollection;
  },

  getCompanyAdminPaymentMethodByUUID(state) {
    return uuid => state.companyAdminPaymentMethodCollection
      .filter(paymentMethod => paymentMethod.getUUID() === uuid)[0];
  },

  getActiveCompanyPaymentMethodByCollection(state) {
    if (!!state.companyAdminPaymentMethodCollection
        && state.companyAdminPaymentMethodCollection.length > 0) {
      return state.companyAdminPaymentMethodCollection
        .filter(paymentMethod => paymentMethod.default === true)[0] || null;
    }

    return null;
  },
};
