import getInitialState from './_state';

export const TYPE = {
  BIND_SERVICE_PROVIDER: 'BIND_SERVICE_PROVIDER',

  RESET_STATE: 'RESET_STATE',
};

export default {
  [TYPE.BIND_SERVICE_PROVIDER](state, { service, value }) {
    return Object.assign(state, { [service]: value });
  },

  [TYPE.RESET_STATE](state, service) {
    let reset = getInitialState();

    if (Object.keys(state).filter(key => key === service).length === 1) {
      reset = { [service]: reset[service] };
    }

    return Object.assign(state, reset);
  },
};
