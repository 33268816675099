import { PaymentMethod } from '@Shared/legacy/PaymentMethod';
import { TYPE } from './_mutations';

export default {
  setPaymentMethodCollection(context, paymentMethodListResponse) {
    const paymentMethodCollection = paymentMethodListResponse.map(item => new PaymentMethod({
      uuid: item.uuid,
      type: item.type,
      number: item.number,
      expiry: item.expiry,
      method: item.method,
      holder: item.holder,
    }));

    context.commit(TYPE.SET_PAYMENT_METHOD_COLLECTION, paymentMethodCollection);
  },

  resetPaymentMethodStore(context) {
    context.commit(TYPE.RESET_STATE);
  },
};
