import get from 'lodash/get';

export const GETTERS = {
  getCompanyData: 'getCompanyData',
  userHasCompany: 'userHasCompany',
  companyUuid: 'companyUuid',
  companyAllowsAdditionalDriver: 'companyAllowsAdditionalDriver',
};

export default {
  [GETTERS.getCompanyData](state) {
    return state.data;
  },

  [GETTERS.userHasCompany](state) {
    return state.data && state.data.getUUID();
  },

  [GETTERS.companyUuid](state) {
    return get(state, 'data.uuid');
  },

  [GETTERS.companyAllowsAdditionalDriver](state) {
    return get(state, 'data.isAdditionalDriverAllowed');
  },
};
