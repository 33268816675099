const mutations = {
  locale(state, locale) {
    state.locale = locale;
  },
  notifyErrorCallback(state, notifyError) {
    state.notifyErrorCallback = notifyError;
  },
  countries(state, countries) {
    state.countries = countries;
  },
  titles(state, titles) {
    state.titles = titles;
  },
  internalTesting(state, internalTesting) {
    state.internalTesting = internalTesting;
  },
};
export default mutations;
