import axios from 'axios';
import cfg from '@/config';
import { ApiHeaders } from '@/vue/api/api.service';

const host = cfg.data.phoenixApiUrl;

const externalApi = {
  linkExistingUser(params) {
    return axios.post(`${host}/user-company/v2/companies/link`, params, {
      headers: ApiHeaders({ auth: true }),
    });
  },

  validateInvitation(invitationToken, invitationUUID) {
    return axios({
      method: 'GET',
      headers: ApiHeaders(),
      url: `${host}/user-company/v1/employees/${invitationUUID}/${invitationToken}/validate-invite`,
    });
  },

  getUserDetail(idpUuid) {
    return axios
      .get(`${host}/user-company/v1/users/${idpUuid}/from-idp`, {
        headers: ApiHeaders({ auth: true }),
      })
      .then(response => response.data)
      .catch(response => response);
  },

  linkPersonalAccountToB2BUsingInvitation(providedIdpUUID, invitationToken, params) {
    return axios.put(
      `${host}/user-company/v1/employees/${providedIdpUUID}/invite/${invitationToken}/link`,
      params,
      {
        headers: ApiHeaders({ auth: true }),
      },
    );
  },

  createEmployeeFromInvitation(token, params) {
    return this.create(`${host}/user-company/v1/employees/invite/${token}`, params);
  },

  createEmployee(params) {
    return this.create(`${host}/user-company/v1/companies/user`, params);
  },

  requestResetPassword(email) {
    const params = {
      email,
    };

    return axios.post(`${host}/user-company/v2/users/reset-auth0-password`, params, {
      headers: ApiHeaders(),
    });
  },

  /**
   * Generic method to something using a No-Authenticated endpoint
   * @param url
   * @param data
   * @returns {AxiosPromise}
   */
  create(url, data) {
    return axios.post(url, data, {
      headers: ApiHeaders(),
    });
  },

};

export default externalApi;
