import getInitialState from './_state';
import mutations from './_mutations';
import actions from './_actions';
import getters from './_getters';

export default {
  namespaced: true,
  state: getInitialState(),
  mutations,
  actions,
  getters,
};

export const nameSpace = 'DateTime';

export { ACTIONS } from './_actions';
export { GETTERS } from './_getters';
export { MUTATIONS } from './_mutations';
