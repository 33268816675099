const state = () => ({
  currentStep: 'step1',
  lastStep: 1,
  notifyErrorCallback: null,
  done: {
    step1: false,
    step2: false,
    step3: false,
  },
  info: {
    countries: [],
    titles: [],
    legalDocuments: [],
    accountCreated: null,
  },
  data: {
    email: '',
    password: '',
    country: null,
    title: null,
    firstName: '',
    lastName: '',
    birthDate: null,
    taxNumber: '',
    idNumber: '',
    address: '',
    addressDetails: '',
    phone: '',
    legalDocumentsAccepted: [],
    receiveNews: false,
    promoCode: '',
    tracking_id: '',
    italianFiscalCode: '',
  },
  b2b: {
    invitation: {
      valid_invite: false,
      company: {},
      invite: {},
    },
    link: {
      email: null,
      phone: null,
    },
    response: null,
    invitationToken: null,
    invitationUUID: null,
    isEmployee: false,
    company: null,
    isCorporateUser: false,
  },
  validation: {
    isPhoneNumberValid: false,
    forceErrorOnWrongPromoCode: false,
  },
});
export default state;
