import { Insurance } from '@Profile/legacy/Insurance';

import { TYPE } from './_mutations';

export default {
  setUserInsurance(context, data) {
    const insurance = new Insurance(data);

    context.commit(TYPE.SET_USER_INSURANCE, insurance);
  },

  resetUserDataStore(context) {
    context.commit(TYPE.SET_RESET_STATE);
  },
};
