export const TYPE = {
  SET_LOCATIONS_COLLECTION: 'SET_LOCATIONS_COLLECTION',
};

export default {
  [TYPE.SET_LOCATIONS_COLLECTION](state, locationsCollection) {
    return Object.assign(state, {
      locationsCollection,
    });
  },

};
