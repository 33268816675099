import Api from '@/vue/api/backoffice';
import { TYPE } from './_mutations';

export default {
  contractTariff(context, { tariffUUID, periodicity }) {
    // Get company data from other Store Module
    const company = context.rootGetters['Company/getCompanyData'];

    // Company should exist
    if (!company) {
      throw new Error('Company does not exist');
    }

    return Api.pricing.contractBusinessTariff(
      tariffUUID,
      company.getUUID(),
      {
        periodicity,
      },
    );
  },

  cancelSubscriptionTariff(context, { tariffUUID }) {
    // Get company data from other Store Module
    const company = context.rootGetters['Company/getCompanyData'];

    // Company should exist
    if (!company) {
      throw new Error('Company does not exist');
    }

    return Api.pricing.cancelCompanyTariffSubscription(
      tariffUUID,
      company.getUUID(),
    );
  },

  resetTariff(context) {
    context.commit(TYPE.RESET_STATE);
  },
};
