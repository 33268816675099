import getInitialState from './_state';

export const TYPE = {
  SET_STACK: 'SET_STACK',
  ADD_MODAL_COMPONENT: 'ADD_MODAL_COMPONENT',
  REMOVE_MODAL_COMPONENT: 'REMOVE_MODAL_COMPONENT',
  SET_STATUS: 'SET_STATUS',
  RESET_STATE: 'RESET_STATE',
};

export default {
  [TYPE.ADD_MODAL_COMPONENT](state, modalComponent) {
    const stack = [modalComponent, ...state.stack];

    return Object.assign(state, {
      stack,
    });
  },

  [TYPE.REMOVE_MODAL_COMPONENT](state) {
    const stack = state.stack.filter((item, index) => index !== 0);

    return Object.assign(state, {
      stack,
    });
  },

  [TYPE.SET_STATUS](state, opened) {
    return Object.assign(state, {
      opened,
    });
  },

  [TYPE.RESET_STATE](state) {
    return Object.assign(state, getInitialState());
  },
};
