import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';

import Location from '@Shared/legacy/Location';

import { TYPE } from './_mutations';

export default {
  setLocationCollection(context, locationsArray) {
    const locations = {};

    if (isEmpty(locationsArray)) {
      return;
    }

    forEach(locationsArray, data => {
      let location = null;

      if (data.constructor === Location) {
        location = data;
      } else {
        location = new Location(data);
      }

      locations[`${location.getUUID()}`] = location;
    });

    context.commit(TYPE.SET_LOCATIONS_COLLECTION, locations);
  },

};
