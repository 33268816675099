import getInitialState from './_state';

export const TYPE = {
  CREATE_NOTIFICATION: 'CREATE_NOTIFICATION',

  DELETE_NOTIFICATION: 'DELETE_NOTIFICATION',

  DELETE_ALL_NOTIFICATIONS: 'DELETE_ALL_NOTIFICATIONS',

  RESET_STATE: 'RESET_STATE',
};

export default {
  [TYPE.CREATE_NOTIFICATION](state, notification) {
    const newList = [...state.list, notification];

    Object.assign(state, { list: newList });
  },

  [TYPE.DELETE_NOTIFICATION](state, notification) {
    const newList = state.list.filter(item => {
      if (item.getId() !== notification.getId()) {
        return true;
      }

      return false;
    });

    Object.assign(state, { list: newList });
  },

  [TYPE.DELETE_ALL_NOTIFICATIONS](state) {
    return Object.assign(state, {
      list: [],
    });
  },

  [TYPE.RESET_STATE](state) {
    return Object.assign(state, getInitialState());
  },
};
