import externalApi from '@/vue/api/external/app.external-api';
import { SET_API_REQUEST_RESET_PASSWORD_RUNNING } from './mutations';

export const REQUEST_RESET_PASSWORD = 'requestResetPassword';

const actions = {
  [REQUEST_RESET_PASSWORD]({ commit }, email) {
    commit(SET_API_REQUEST_RESET_PASSWORD_RUNNING, true);

    return externalApi.requestResetPassword(email)
      .then(response => {
        commit(SET_API_REQUEST_RESET_PASSWORD_RUNNING, false);
        return response;
      })
      .catch(e => {
        commit(SET_API_REQUEST_RESET_PASSWORD_RUNNING, false);
        throw e;
      });
  },
};

export default actions;
