import getInitialState from './_state';

export const TYPE = {
  SET_USER_INSURANCE: 'SET_USER_INSURANCE',

  SET_RESET_STATE: 'SET_RESET_STATE',
};

export default {
  [TYPE.SET_USER_INSURANCE](state, currentInsurance) {
    return Object.assign(state, {
      currentInsurance,
    });
  },

  [TYPE.SET_RESET_STATE](state) {
    return Object.assign(state, getInitialState());
  },
};
