const types = {
  SET_SUMMARY: 'SET_SUMMARY',
  SET_VEHICLE: 'SET_VEHICLE',
  SET_LOCATION: 'SET_LOCATION',
  SET_RESERVATION: 'SET_RESERVATION',
  RESET_STATE: 'RESET_STATE',
  START_BOOKING: 'START_BOOKING',
  SET_ACTIVE_BOOKINGS: 'SET_ACTIVE_BOOKINGS',
  SET_REQUEST_BOOKINGS: 'SET_REQUEST_BOOKINGS',
  SET_ACTIVE_AND_REQUEST_BOOKINGS: 'SET_ACTIVE_AND_REQUEST_BOOKINGS',
  SET_ACTIVE_INTERVENTION_BOOKINGS: 'SET_ACTIVE_INTERVENTION_BOOKINGS',
  SET_EMPLOYEE_BEHALF: 'SET_EMPLOYEE_BEHALF',
  SET_ACTIVE_BOOKINGS_PAGINATION: 'SET_ACTIVE_BOOKINGS_PAGINATION',
  SET_ACTIVE_INTERVENTION_BOOKINGS_PAGINATION: 'SET_ACTIVE_INTERVENTION_BOOKINGS_PAGINATION',
  SET_HISTORY_BOOKINGS: 'SET_HISTORY_BOOKINGS',
  SET_HISTORY_INTERVENTION_BOOKINGS: 'SET_HISTORY_INTERVENTION_BOOKINGS',
  SET_HISTORY_BOOKINGS_PAGINATION: 'SET_HISTORY_BOOKINGS_PAGINATION',
  SET_HISTORY_INTERVENTION_BOOKINGS_PAGINATION: 'SET_HISTORY_INTERVENTION_BOOKINGS_PAGINATION',
  SET_BOOKING_TO_LOG: 'setBookingToLog',
  SET_CARPOOLING_DATA: 'setCarpoolingData',
  SET_ESTIMATED_TRIP_MILEAGE: 'setEstimatedTripMileage',
};

export default types;
