import Api from '@/vue/api/backoffice';
import types from './_types';

export const ACTIONS = {
  resetState: 'RESET_STATE',
};

export default {
  mapActiveBookings({ commit }, bookings) {
    const activeBookings = {

      bookings: (bookings || []),
      loading: !!bookings === false,
    };
    commit(types.SET_ACTIVE_BOOKINGS, activeBookings);
  },
  mapActiveInterventionBookings({ commit }, bookings) {
    const activeInterventionBookings = {

      bookings: (bookings || []),
      loading: !!bookings === false,
    };
    commit(types.SET_ACTIVE_INTERVENTION_BOOKINGS, activeInterventionBookings);
  },

  mapActiveBookingsPagination({ commit }, pagination) {
    commit(types.SET_ACTIVE_BOOKINGS_PAGINATION, pagination);
  },
  mapActiveInterventionBookingsPagination({ commit }, pagination) {
    commit(types.SET_ACTIVE_INTERVENTION_BOOKINGS_PAGINATION, pagination);
  },

  mapRequestBookings({ commit }, bookings) {
    const requestBookings = {

      bookings: (bookings || []),
      loading: !!bookings === false,
    };
    commit(types.SET_REQUEST_BOOKINGS, requestBookings);
  },

  mapActiveAndRequestBookings({ commit }, { bookings, requestBookings }) {
    const allBookings = {

      bookings: (bookings || []),
      requestBookings: (requestBookings.items || []),
      loading: !!bookings === false,
    };
    commit(types.SET_ACTIVE_AND_REQUEST_BOOKINGS, allBookings);
  },

  mapHistoryBookings({ commit }, bookings) {
    const historyBookings = {

      bookings: (bookings || []),
      loading: !!bookings === false,
    };
    commit(types.SET_HISTORY_BOOKINGS, historyBookings);
  },
  mapHistoryInterventionBookings({ commit }, bookings) {
    const historyInterventionBookings = {

      bookings: (bookings || []),
      loading: !!bookings === false,
    };
    commit(types.SET_HISTORY_INTERVENTION_BOOKINGS, historyInterventionBookings);
  },

  mapHistoryBookingsPagination({ commit }, pagination) {
    commit(types.SET_HISTORY_BOOKINGS_PAGINATION, pagination);
  },
  mapHistoryInterventionBookingsPagination({ commit }, pagination) {
    commit(types.SET_HISTORY_INTERVENTION_BOOKINGS_PAGINATION, pagination);
  },

  resetActiveBookingsCache() {
    Api.carsharing.activeBookingsRequests = { params: null, time: null, request: null };
  },

  resetHistoryBookingsCache() {
    Api.carsharing.historyBookingsRequests = { params: null, time: null, request: null };
  },

  [ACTIONS.resetState]({ commit }) {
    commit(types.RESET_STATE);
  },
};
