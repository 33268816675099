import getInitialState from './_state';

export const TYPE = {
  SET_AUTHENTICATED_USER: 'SET_AUTHENTICATE_USER',

  SET_GEOLOCATION: 'SET_GEOLOCATION',

  SET_PROFILE_PERMISSIONS: 'setProfilePermissions',

  SET_CIRCLE_MANAGER_PERMISSIONS: 'setCircleManagerPermissions',

  setReferralInfo: 'setReferralInfo',
};

export default {
  [TYPE.SET_AUTHENTICATED_USER](state, userData) {
    return Object.assign(state, {
      userData,
    });
  },

  [TYPE.SET_GEOLOCATION](state, geolocation) {
    return Object.assign(state, {
      geolocation,
    });
  },

  [TYPE.RESET_STATE](state) {
    return Object.assign(state, getInitialState());
  },

  [TYPE.SET_PROFILE_PERMISSIONS](state, { profileUuid, permissions }) {
    state.permissions[profileUuid] = permissions;
  },

  [TYPE.SET_CIRCLE_MANAGER_PERMISSIONS](state, permissions) {
    state.circleManagerPermissions = permissions;
  },

  [TYPE.setReferralInfo](state, referralInfo) {
    state.referralInfo = referralInfo;
  },
};
