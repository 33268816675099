import getInitialState from './_state';

export const TYPE = {

  SET_BOOKING_TYPE_COLLECTION: 'SET_BOOKING_TYPE_COLLECTION',

  SET_CURRENT_BOOKING_TYPE: 'SET_CURRENT_BOOKING_TYPE',

  RESET_STATE: 'RESET_STATE',
};

export default {

  [TYPE.SET_BOOKING_TYPE_COLLECTION](state, bookingTypeCollection) {
    return Object.assign(state, {
      bookingTypeCollection,
    });
  },

  [TYPE.SET_CURRENT_BOOKING_TYPE](state, currentBookingType) {
    return Object.assign(state, {
      currentBookingType,
    });
  },

  [TYPE.RESET_STATE](state) {
    return Object.assign(state, getInitialState());
  },
};
