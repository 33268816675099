import { DELAY } from '@emobg/web-utils';
import cfg from '@/config';

const GoogleMaps = require('../../../node_modules/google-maps');

GoogleMaps.LIBRARIES = ['geometry', 'places'];

GoogleMaps.KEY = cfg.data.googleMapsKey;

class GoogleMapsLoader {
  constructor() {
    this.instance = null;
    this.googleMapsAttached = new Promise(() => {});
  }

  setInstance(google) {
    this.instance = google;
  }

  getInstance() {
    return this.instance;
  }

  waitUntilGoogleMapsIsAttached() {
    return this.googleMapsAttached;
  }

  createInstance() {
    const self = this;

    let interval = null;

    return new Promise(resolve => {
      interval = setInterval(() => {
        if (global.google) {
          this.setInstance(global.google);
          clearInterval(interval);
          this.googleMapsAttached = Promise.resolve();
          resolve(self.getInstance());
        }
      }, DELAY.short);
    });
  }
}

const googleMapsLoader = new GoogleMapsLoader();

export default googleMapsLoader;
