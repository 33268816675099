import get from 'lodash/get';

const GETTERS = {
  italianFiscalCode: 'italianFiscalCode',
  addressCountryCode: 'addressCountryCode',
};

export default {
  [GETTERS.italianFiscalCode]: state => get(state, 'data.italianFiscalCode'),
  [GETTERS.addressCountryCode]: state => get(state, 'data.companyAddress.countryCode'),
};
