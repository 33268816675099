import state from './_state';
import mutations from './_mutations';
import getters from './_getters';
import actions from './_actions';

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};

export const nameSpace = 'Booking';

export { default as MUTATIONS } from './_types';
export { ACTIONS } from './_actions';
