import { axiosClientConfig } from '@emobg/web-api-client';
import {
  LOG_TYPE,
  getIdpUuid,
  logger,
  rehydrate,
} from '@emobg/web-utils';

import get from 'lodash/get';
import isBoolean from 'lodash/isBoolean';
import isNil from 'lodash/isNil';
import isPlainObject from 'lodash/isPlainObject';

import config from '@/config';
import languageService from '@/services/Language';

import { MFA } from '@/constants/mfa';

import { useAuth } from '@/composable/Api/Auth/auth';

/**
 * By default, the headers of the endpoints
 * are set to no-authentication required
 * @type {{auth: boolean}}
 */
const defaultApiHeadersConfiguration = {
  auth: false,
  locale: 'en',
};

/**
 * Returns headers configuration for endpoints
 * @param customConfig
 * @returns {{Authorization: string, 'X-Requested-With': string, 'Accept-Language': *, 'user-device-token': string}}
 */
export const ApiHeaders = customConfig => {
  const { accessToken, idpUuid: idpToken } = useAuth();
  // eslint-disable-next-line global-require
  const { default: store } = require('@/vue/stores/Store');
  const profileUuid = get(store, 'getters.Profile/getCurrentProfile.uuid');
  const mergedConfig = {
    ...defaultApiHeadersConfiguration,
    locale: languageService.getLanguage(),
    ...customConfig,
  };

  // Set default headers
  let headers = {
    Authorization: '',
    'X-Requested-With': 'XMLHttpRequest',
    'Accept-Language': languageService.getLanguageCodeForHeader(mergedConfig.locale),
    source: 'webapp',
    impersonated: localStorage.getItem('is_impersonate'),
  };

  if (profileUuid) {
    headers = {
      ...headers,
      'user-profile-uuid': profileUuid,
    };
  }

  // Headers required when endpoint needs a authentication headers
  if (isBoolean(mergedConfig.auth) && mergedConfig.auth) {
    // Set the Bearer access token
    if (accessToken) {
      headers = {
        ...headers,
        Authorization: `Bearer ${accessToken}`,
      };
    }

    // Set the MFA device token
    const key = MFA.userDeviceStorageKey.replace(MFA.userUuidStorageKeyPlaceholder, getIdpUuid(idpToken));
    const twoFactorDeviceToken = get(rehydrate.get(key), MFA.userDeviceStorageTokenKey);
    if (twoFactorDeviceToken) {
      headers = {
        ...headers,
        [MFA.userDeviceHeaderKey]: twoFactorDeviceToken,
      };
    }
  }

  return headers;
};

export const ParseUrlQueryString = parameters => {
  if (!isPlainObject(parameters)) {
    logger.message('ParseUrlQueryString does not receive an object', LOG_TYPE.warning);
    return '';
  }

  return Object
    .keys(parameters)
    .filter(key => !isNil(parameters[key]))
    .map(key => `${key}=${parameters[key]}`)
    .join('&');
};

export const initAuthAxiosClientConfig = (apiHeadersConfig = {}) => {
  const customHeaders = ApiHeaders({ ...apiHeadersConfig, auth: true });

  axiosClientConfig.init(
    {
      host: config.data.phoenixApiUrl,
      token: get(customHeaders, 'Authorization'),
      responseInterceptors: [],
    },
    customHeaders,
  );
};

export const initGuestAxiosClientConfig = () => {
  const customHeaders = ApiHeaders({ auth: false });

  axiosClientConfig.init(
    {
      host: config.data.phoenixApiUrl,
      token: '',
      responseInterceptors: [],
    },
    customHeaders,
  );
};

export default {
  ApiHeaders,
};
