export const GETTERS = {
  getBookingTypeCollection: 'getBookingTypeCollection',
  getCurrentBookingType: 'getCurrentBookingType',
};

export default {

  [GETTERS.getBookingTypeCollection](state) {
    return state.bookingTypeCollection;
  },

  [GETTERS.getCurrentBookingType](state) {
    return state.currentBookingType;
  },

};
