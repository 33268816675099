import getInitialState from './_state';

export const TYPE = {
  SET_COMPANY_ADMIN_PAYMENT_METHOD_COLLECTION: 'SET_COMPANY_ADMIN_PAYMENT_METHOD_COLLECTION',

  RESET_STATE: 'RESET_STATE',
};

export default {
  [TYPE.SET_COMPANY_ADMIN_PAYMENT_METHOD_COLLECTION](state, companyAdminPaymentMethodCollection) {
    return Object.assign(state, {
      companyAdminPaymentMethodCollection,
    });
  },

  [TYPE.RESET_STATE](state) {
    return Object.assign(state, getInitialState());
  },
};
