import toUpper from 'lodash/toUpper';
import getInitialState from './state';

export const MUTATIONS = {
  doneStep: 'doneStep',
  undoStep: 'undoStep',
  notifyErrorCallback: 'notifyErrorCallback',
  legalForms: 'legalForms',
  legalDocuments: 'legalDocuments',
  legalDocumentsAccepted: 'legalDocumentsAccepted',
  csOperator: 'csOperator',
  sectorEntities: 'sectorEntities',
  adminFiscalNumber: 'adminFiscalNumber',
  updateStep: 'updateStep',
  companySizes: 'companySizes',
  lastStep: 'lastStep',
  companyName: 'companyName',
  fiscalNumber: 'fiscalNumber',
  taxNumber: 'taxNumber',
  workEmail: 'workEmail',
  workPhoneNumber: 'workPhoneNumber',
  companyEmail: 'companyEmail',
  password: 'password',
  title: 'title',
  firstName: 'firstName',
  lastName: 'lastName',
  birthDate: 'birthDate',
  phoneNumber: 'phoneNumber',
  companyAddress: 'companyAddress',
  extraCompanyAddress: 'extraCompanyAddress',
  legalForm: 'legalForm',
  sectorEntity: 'sectorEntity',
  companySize: 'companySize',
  fiscalCode: 'fiscalCode',
  italyTermsAndConditions: 'italyTermsAndConditions',
  countries: 'countries',
  titles: 'titles',
  extraFields: 'extraFields',
  resetCompany: 'resetCompany',
  linkAccountUser: 'linkAccountUser',
  isLinkAccount: 'isLinkAccount',
  marketingTerms: 'marketingTerms',
  linkAccountIdpUuid: 'linkAccountIdpUuid',
  setItalianFiscalCode: 'setItalianFiscalCode',
  italyIdFront: 'italyIdFront',
  italyIdBack: 'italyIdBack',
  italyPassport: 'italyPassport',
  setItalyCertificate: 'setItalyCertificate',
  setIsEmailValid: 'setIsEmailValid',
  setServiceCountryCode: 'setServiceCountryCode',
  setVatNumber: 'setVatNumber',
  setSiretNumber: 'setSiretNumber',
  setCompanyData: 'setCompanyData',
};

export default {
  [MUTATIONS.doneStep](state, value) {
    state.done[value] = true;
  },
  [MUTATIONS.undoStep](state, value) {
    Object.keys(state.done).forEach(step => {
      if (step >= value) {
        state.done[step] = false;
      }
    });
  },
  [MUTATIONS.notifyErrorCallback](state, notifyError) {
    state.notifyErrorCallback = notifyError;
  },
  [MUTATIONS.legalForms](state, value) {
    state.info.legalForms = value;
  },
  [MUTATIONS.legalDocuments](state, documents) {
    state.info.legalDocuments = documents;
  },
  [MUTATIONS.legalDocumentsAccepted](state, accepted) {
    state.data.legalDocumentsAccepted = accepted;
  },
  [MUTATIONS.csOperator](state, value) {
    state.info.csOperator = value;
  },
  [MUTATIONS.sectorEntities](state, value) {
    state.info.sectorEntities = value;
  },
  [MUTATIONS.adminFiscalNumber](state, value) {
    state.data.adminFiscalNumber = value;
  },
  [MUTATIONS.updateStep](state, value) {
    state.currentStep = value;
  },
  [MUTATIONS.companySizes](state, value) {
    state.info.companySizes = value;
  },
  [MUTATIONS.lastStep](state, step) {
    state.lastStep = step;
  },
  // Step 1
  // Company information
  [MUTATIONS.companyName](state, value) {
    state.data.companyName = value;
  },
  [MUTATIONS.fiscalNumber](state, value) {
    state.data.fiscal_number = value;
  },
  [MUTATIONS.taxNumber](state, value) {
    if (value.length > 2) {
      state.data.taxNumber = value;
    } else {
      state.data.taxNumber = '';
    }
  },
  [MUTATIONS.setVatNumber](state, value) {
    state.data.vatNumber = value;
  },
  [MUTATIONS.setServiceCountryCode](state, value) {
    state.data.serviceCountryCode = value;
  },
  // Work contact information
  [MUTATIONS.workEmail](state, value) {
    state.data.workEmail = value;
  },
  [MUTATIONS.workPhoneNumber](state, value) {
    state.data.workPhoneNumber = value;
  },
  // Admin login
  [MUTATIONS.companyEmail](state, value) {
    state.data.companyEmail = value;
  },
  [MUTATIONS.password](state, value) {
    state.data.password = value;
  },
  [MUTATIONS.title](state, value) {
    state.data.title = value;
  },
  [MUTATIONS.firstName](state, value) {
    state.data.firstName = value;
  },
  [MUTATIONS.lastName](state, value) {
    state.data.lastName = value;
  },
  [MUTATIONS.birthDate](state, value) {
    state.data.birthDate = value;
  },
  [MUTATIONS.phoneNumber](state, value) {
    state.data.phoneNumber = value;
  },

  // ----
  [MUTATIONS.companyAddress](state, value) {
    state.data.companyAddress = value;
  },
  [MUTATIONS.extraCompanyAddress](state, value) {
    state.data.extraCompanyAddress = value;
  },

  [MUTATIONS.legalForm](state, value) {
    state.data.legalForm = value;
  },
  [MUTATIONS.sectorEntity](state, value) {
    state.data.sectorEntity = value;
  },
  [MUTATIONS.companySize](state, value) {
    state.data.companySize = value;
  },
  [MUTATIONS.fiscalCode](state, value) {
    state.data.fiscalCode = value;
  },
  [MUTATIONS.italyTermsAndConditions](state, value) {
    state.data.italyTermsAndConditions = value;
  },
  [MUTATIONS.countries](state, value) {
    state.info.countries = value;
  },
  [MUTATIONS.titles](state, value) {
    state.info.titles = value;
  },
  [MUTATIONS.extraFields](state, value) {
    state.info.extraFields = value;
  },
  [MUTATIONS.resetCompany](state) {
    return Object.assign(state, getInitialState());
  },
  [MUTATIONS.linkAccountUser](state, value) {
    state.linkAccount.userData = value;
  },
  [MUTATIONS.isLinkAccount](state, value) {
    state.linkAccount.isLinkAccount = value;
  },
  [MUTATIONS.marketingTerms](state, value) {
    state.data.marketingTerms = value;
  },
  [MUTATIONS.linkAccountIdpUuid](state, value) {
    state.linkAccount.idpUuid = value;
  },
  [MUTATIONS.italyPassport](state, value) {
    state.data.extraDocuments.italianPassport = value;
  },
  [MUTATIONS.italyIdFront](state, value) {
    state.data.extraDocuments.italianIdFront = value;
  },
  [MUTATIONS.italyIdBack](state, value) {
    state.data.extraDocuments.italianIdBack = value;
  },
  [MUTATIONS.setItalianFiscalCode](state, italianFiscalCode) {
    state.data.italianFiscalCode = toUpper(italianFiscalCode);
  },
  [MUTATIONS.setItalyCertificate](state, italianCertificate) {
    state.data.extraDocuments.italianCertificate = italianCertificate;
  },
  [MUTATIONS.setIsEmailValid](state, isValid) {
    state.info.isEmailValid = isValid;
  },
  [MUTATIONS.setSiretNumber](state, siretNumber) {
    state.data.siretNumber = siretNumber;
  },
  [MUTATIONS.setCompanyData](state, data) {
    state.data = data;
  },
};
