import get from 'lodash/get';
import { COUNTRIES_ISO_CODES } from '@emobg/web-utils';

const GETTERS = {
  italianFiscalCode: 'italianFiscalCode',
  countryCode: 'countryCode',
  addressCountryCode: 'addressCountryCode',
};

export default {
  [GETTERS.italianFiscalCode]: state => get(state, 'data.italianFiscalCode'),
  [GETTERS.countryCode]: state => get(state, 'data.country.code') || get(state, 'b2b.invitation.company.country_code') || COUNTRIES_ISO_CODES.spain,
  [GETTERS.addressCountryCode]: state => get(state, 'data.address.countryCode'),
};
