import Company from './company/index';
import User from './user/index';
import Generic from './generic/index';
import ResetPassword from './reset-password';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  modules: {
    SignUp: {
      namespaced: true,
      modules: {
        Company,
        User,
        Generic,
      },
    },
    ResetPassword,
  },
};

export const nameSpace = 'Refactor';
