import { PaymentMethod } from '@Shared/legacy/PaymentMethod';
import Api from '@/vue/api/backoffice';
import { TYPE } from './_mutations';

export default {
  setPaymentMethodList(context, paymentMethodListResponse) {
    const paymentMethodCollection = paymentMethodListResponse.map(item => new PaymentMethod({
      active: item.active,
      default: item.default,
      expiry: item.expiry,
      holder: item.holder,
      internal: item.internal,
      number: item.number,
      status: item.status,
      type: item.type,
      uuid: item.uuid,
    }));

    context.commit(TYPE.SET_PAYMENT_METHOD_LIST, paymentMethodCollection);
  },

  setErrorMessagePaymentMethod(context, message) {
    context.commit(TYPE.SET_ERROR_MESSAGE, message);
  },

  setDefaultPaymentMethod(context, paymentMethodUUID) {
    // Get company data from other Store Module
    const company = context.rootGetters['Company/getCompanyData'];

    // Get user data from other Store Module
    const user = context.rootGetters['UserData/getUserData'];

    // We have to create a promise to disable the
    // button, because user will see that payment method
    // is changed but really, it does not change
    // untill backend responses me.
    return new Promise(resolve => {
      // Company and User should exist
      if (!company) {
        throw new Error('Company does not exist');
      }

      if (!user) {
        throw new Error('User does not exist');
      }

      // Change current payment method from Store
      // because we have to call 2 endpoints and
      // we have to wait a lot of time... and it's
      // bad UX
      const currentPaymentMethod = context.getters.getCurrentPaymentMethod;
      if (currentPaymentMethod) {
        currentPaymentMethod.setDefault(false);
      }

      const paymentMethodByUUID = context.getters.getPaymentMethodByUUID(paymentMethodUUID);
      if (paymentMethodByUUID) {
        paymentMethodByUUID.setDefault(true);
      }

      // Set default company payment method
      return Api.payments.setDefaultCompanyPaymentMethod(
        company.getUUID(),
        paymentMethodUUID,
        user.getUUID(),
      ).then(() => {
        resolve();
      }).catch(() => {
        // If someone fails, revert the changes
        if (currentPaymentMethod) {
          currentPaymentMethod.setDefault(true);
        }

        if (paymentMethodByUUID) {
          paymentMethodByUUID.setDefault(false);
        }
      }).then(() => {
        // Renew the list
        context.dispatch('fetchPaymentMethodList');
      });
    });
  },

  fetchPaymentMethodList(context) {
    // Get company data from other Store Module
    const company = context.rootGetters['Company/getCompanyData'];

    if (!company) {
      throw new Error('Company does not exist');
    }

    return Api.payments.listCompanyPaymentMethods(company.getUUID())
      .then(({ data }) => {
        context.dispatch('setPaymentMethodList', data);

        return data;
      });
  },

  resetPaymentMethodSummary(context) {
    context.commit(TYPE.RESET_STATE);
  },
};
