const getState = () => ({

  start: undefined,

  end: undefined,

  // Used when you have to define more DateTime
  datetime: {},

});

export default getState;
