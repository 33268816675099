import getInitialState from './_state';

export const TYPE = {
  RESET_STATE: 'RESET_STATE',
};

export default {
  [TYPE.RESET_STATE](state) {
    return Object.assign(state, getInitialState());
  },
};
