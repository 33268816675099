import getInitialState from './_state';

export const TYPE = {
  SET_ACTIVE_TAB_VALUE: 'SET_ACTIVE_TAB_VALUE',

  RESET_STATE: 'RESET_STATE',
};

export default {
  [TYPE.SET_ACTIVE_TAB_VALUE](state, tab) {
    return Object.assign(state, {
      activeTabValue: tab,
    });
  },

  [TYPE.RESET_STATE](state) {
    return Object.assign(state, getInitialState());
  },
};
