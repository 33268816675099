export default {
  getPaymentMethodList(state) {
    return state.list;
  },

  getPaymentMethodByUUID(state) {
    if (!state.list) {
      return null;
    }

    return uuid => state.list.filter(paymentMethod => paymentMethod.getUUID() === uuid)[0];
  },

  getCurrentPaymentMethod(state) {
    if (!state.list) {
      return null;
    }

    return state.list.filter(paymentMethod => paymentMethod.isDefault())[0];
  },

  getErrorMessage(state) {
    return state.error;
  },
};
