import { TYPE } from './_mutations';

export default {
  /**
   * @param context
   * @param activeTabValue
   */
  setActiveTabValue(context, activeTabValue) {
    context.commit(TYPE.SET_ACTIVE_TAB_VALUE, activeTabValue);
  },

  resetActiveTabStore(context) {
    context.commit(TYPE.RESET_STATE);
  },
};
