export default {
  isOpened(state) {
    return state.opened;
  },

  getModalComponentStack(state) {
    return state.stack;
  },

  getComponent(state) {
    return state.stack && state.stack[0] && state.stack[0].getComponent();
  },

  isSameComponent(state) {
    const lastComponent = state.stack && state.stack[0] && state.stack[0].getComponent();
    return (component => lastComponent === component);
  },

  getModalParams(state) {
    return state.stack && state.stack[0] && state.stack[0].getParameters();
  },
};
