import concat from 'lodash/concat';
import sortBy from 'lodash/sortBy';
import types from './_types';
import getInitialState from './_state';

export default {
  [types.SET_SUMMARY](state, payload) {
    state.bookingSummary = {

      ...payload,
    };
  },
  [types.SET_RESERVATION](state, payload) {
    state.reservation = {

      ...state.reservation,
      ...payload,
    };
  },
  [types.START_BOOKING](state, payload) {
    state.started = payload.started;
  },
  [types.SET_ACTIVE_BOOKINGS](state, payload) {
    state.activeBookings = {

      ...state.activeBookings,
      ...payload,
    };
  },
  [types.SET_REQUEST_BOOKINGS](state, payload) {
    state.requestBookings = {

      ...state.requestBookings,
      ...payload,
    };
  },
  [types.SET_ACTIVE_AND_REQUEST_BOOKINGS](state, payload) {
    const bookings = concat(payload.bookings, payload.requestBookings);
    const orderBookingsByDate = sortBy(bookings, 'start');
    state.activeAndRequestBookings = {
      loading: false,
      bookings: orderBookingsByDate,
    };
  },
  [types.SET_ACTIVE_INTERVENTION_BOOKINGS](state, payload) {
    state.activeInterventionBookings = {

      ...state.activeInterventionBookings,
      ...payload,
    };
  },
  [types.SET_HISTORY_BOOKINGS](state, payload) {
    state.historyBookings = {

      ...state.historyBookings,
      ...payload,
    };
  },
  [types.SET_HISTORY_INTERVENTION_BOOKINGS](state, payload) {
    state.historyInterventionBookings = {

      ...state.historyInterventionBookings,
      ...payload,
    };
  },
  [types.RESET_STATE](state) {
    return Object.assign(state, getInitialState());
  },
  [types.SET_ACTIVE_BOOKINGS_PAGINATION](state, payload) {
    state.activeBookings = {

      ...state.activeBookings,
      pagination: payload,
    };
  },
  [types.SET_ACTIVE_INTERVENTION_BOOKINGS_PAGINATION](state, payload) {
    state.activeInterventionBookings = {

      ...state.activeInterventionBookings,
      pagination: payload,
    };
  },
  [types.SET_HISTORY_BOOKINGS_PAGINATION](state, payload) {
    state.historyBookings = {

      ...state.historyBookings,
      pagination: payload,
    };
  },
  [types.SET_HISTORY_INTERVENTION_BOOKINGS_PAGINATION](state, payload) {
    state.historyInterventionBookings = {

      ...state.historyInterventionBookings,
      pagination: payload,
    };
  },
  [types.SET_CARPOOLING_DATA](state, payload) {
    state.reservation.carpooling = payload;
  },
  [types.SET_ESTIMATED_TRIP_MILEAGE](state, payload) {
    state.estimatedTripMileage = payload;
  },
};
