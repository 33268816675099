import get from 'lodash/get';
import find from 'lodash/find';
import some from 'lodash/some';
import result from 'lodash/result';

import { DISTANCE_UNIT } from '@emobg/web-utils';

const DEFAULT_SUPPORT_URL = 'https://support.ubeeqo.com/en/support/home';

export const GETTERS = {
  getCurrentCSOperator: 'getCurrentCSOperator',
  getCSOperatorCollection: 'getCSOperatorCollection',
  findCSOperatorByUUID: 'findCSOperatorByUUID',
  findOperatorBy: 'findOperatorBy',
  getVisitedCsOperator: 'getVisitedCsOperator',
  hasRestrictiveBadge: 'hasRestrictiveBadge',
  hasLogBook: 'hasLogBook',
  hasOneWay: 'hasOneWay',
  operatorHasOneWay: 'operatorHasOneWay',
  getOperatorMileageSymbol: 'getOperatorMileageSymbol',
  getCurrentOperatorServicePhone: 'getCurrentOperatorServicePhone',
  getCurrentOperatorServiceEmail: 'getCurrentOperatorServiceEmail',
  getCurrentOperatorCurrency: 'getCurrentOperatorCurrency',
  getVisitedCsOperatorHelpLink: 'getVisitedCsOperatorHelpLink',
};

export default {
  [GETTERS.getCurrentCSOperator](state) {
    return state.currentOperatorConfig;
  },

  [GETTERS.getCSOperatorCollection](state) {
    return state.operatorConfigCollection;
  },

  [GETTERS.findCSOperatorByUUID](state) {
    return uuid => find(state.operatorConfigCollection, { uuid });
  },

  [GETTERS.findOperatorBy](state) {
    const collection = get(state, 'operatorConfigCollection') || [];

    return filter => find(collection, filter);
  },

  [GETTERS.getVisitedCsOperator](state) {
    return state.csOperatorVisited || state.currentOperatorConfig;
  },

  [GETTERS.hasRestrictiveBadge](state) {
    return some(state.operatorConfigCollection, csOperator => get(csOperator, 'configuration.use_restrictive_badge'));
  },

  [GETTERS.hasLogBook](state) {
    return some(state.operatorConfigCollection, csOperator => get(csOperator, 'configuration.has_logbook'));
  },

  [GETTERS.hasOneWay](state) {
    return some(state.operatorConfigCollection, csOperator => get(csOperator, 'configuration.has_one_way'));
  },

  [GETTERS.operatorHasOneWay](state, getters) {
    return operatorUuid => get(getters.findCSOperatorByUUID(operatorUuid), 'configuration.has_one_way');
  },

  [GETTERS.getOperatorMileageSymbol](_state, getters) {
    return (get(result(getters, 'getCurrentCSOperator.getConfiguration'), 'mileage_symbol')) || DISTANCE_UNIT.kilometers;
  },

  [GETTERS.getCurrentOperatorServicePhone](_state, getters) {
    return get(getters.getCurrentCSOperator, 'customer_service_phone');
  },

  [GETTERS.getCurrentOperatorServiceEmail](_state, getters) {
    return get(getters.getCurrentCSOperator, 'customer_service_email');
  },

  [GETTERS.getCurrentOperatorCurrency](_state, getters) {
    return get(getters.getCurrentCSOperator, 'configuration.currency_code');
  },

  [GETTERS.getVisitedCsOperatorHelpLink](_state, getters) {
    return get(getters.getVisitedCsOperator, 'help_link') || DEFAULT_SUPPORT_URL;
  },

};
