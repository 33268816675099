import get from 'lodash/get';
import find from 'lodash/find';
import { external } from '@emobg/web-api-client';
import { createCustomStore, withScopes } from '@emobg/web-utils';
import { isRequestHydrated } from '@/constants/app';

const { getCostAllocations, getExternalBooking } = external.companyCostAllocation;

const scopes = {
  cost: 'cost',
  external: 'external',
};

export const ACTIONS = {
  getCostAllocations: 'getCostAllocations',
  getExternalBooking: 'getExternalBooking',
  deleteExternalBooking: 'deleteExternalBooking',
};

export const GETTERS = {
  costAllocations: 'costAllocations',
  getCostAllocationByUuid: 'getCostAllocationByUuid',
  getCostAllocationChildByUuid: 'getCostAllocationChildByUuid',
};

export const nameSpace = 'CostAllocations';
export const CostAllocations = createCustomStore(({ runAsyncFlow }) => withScopes(scopes,
  (
    {
      actions: {
        [ACTIONS.getCostAllocations]({ commit }, { companyUuid, payload }) {
          runAsyncFlow(commit, {
            request: getCostAllocations,
            params: [companyUuid, payload],
            scope: scopes.cost,
            isHydrated: isRequestHydrated,
          });
        },
        [ACTIONS.getExternalBooking]({ commit }, { companyUuid, payload }) {
          return runAsyncFlow(commit, {
            request: getExternalBooking,
            params: [companyUuid, payload],
            scope: scopes.external,
          });
        },
        [ACTIONS.deleteExternalBooking]({ commit }) {
          commit('setData', { scope: scopes.external, value: null });
        },
      },
      getters: {
        [GETTERS.costAllocations]: state => get(state, 'cost.data.data'),

        [GETTERS.getCostAllocationByUuid]: (_state, getters) => uuid => find(getters.costAllocations, { uuid }),

        [GETTERS.getCostAllocationChildByUuid]: (_state, getters) => (parentUuid, childUuid) => find(
          get(getters.getCostAllocationByUuid(parentUuid), 'children'),
          { uuid: childUuid },
        ),
      },
    }
  )));

