import { toBoolean } from '@emobg/web-utils';
import getInitialState from './state';

export const RESET_STATE = 'resetState';
export const SET_API_REQUEST_RESET_PASSWORD_RUNNING = 'setApiRequestResetPasswordRunning';
export const SET_EMAIL_REQUEST_RESET_PASSWORD = 'setEmailRequestPassword';

const mutations = {
  [RESET_STATE](state) {
    return Object.assign(state, getInitialState());
  },

  [SET_EMAIL_REQUEST_RESET_PASSWORD](state, payload) {
    state.requestResetPassword.email = payload;
  },

  [SET_API_REQUEST_RESET_PASSWORD_RUNNING](state, payload) {
    const isRunning = toBoolean(payload);

    state.requestResetPassword.apiRunning = isRunning;
  },
};

export default mutations;
