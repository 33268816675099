import moment from 'moment';
import get from 'lodash/get';
import size from 'lodash/size';
import isNil from 'lodash/isNil';
import filter from 'lodash/filter';
import { isIntervention, isRegular } from '@/helpers/booking/bookingHelpers';

const mapBooking = booking => {
  // Add Vehicle inside Location
  const locationUUID = {
    uuid: `location_${booking.uuid}`,
    original_uuid: booking.location.uuid,
  };
  const veh = { ...booking.vehicle };
  const loc = { ...booking.location, ...locationUUID };
  loc.vehicle = veh;

  const newBookingObject = {

    ...booking,
    location: { ...loc },
  };

  return newBookingObject;
};

export default {
  bookingDates(state) {
    const DATE_FORMAT = 'lll';
    const start = moment(state.reservation.start);
    const end = moment(state.reservation.end);

    return `${start.format(DATE_FORMAT)} / ${end.format(DATE_FORMAT)}`;
  },

  isBooking(state) {
    const booking = state.reservation || {};

    const hasUser = size(booking.user);
    const hasProfile = size(booking.profile);
    const hasVehicle = size(booking.vehicle);
    const hasLocation = size(booking.location);
    const hasSummary = booking.isPreBooking || size(state.bookingSummary);

    return hasUser && hasProfile && hasVehicle && hasLocation && hasSummary && state.started;
  },

  isBookingWithCarpooling(state, getters) {
    return getters.isBooking && state.reservation.useCarpooling === true;
  },

  activeBookingsNumber(state, getters) {
    if (isNil(getters.activeBookingsMappedPagination.total)) {
      return getters.activeBookingsMapped.length;
    }

    return getters.activeBookingsMappedPagination.total || 0;
  },

  activeBookingsLoading(state) {
    return state.activeBookings.loading;
  },
  activeInterventionBookingsLoading(state) {
    return state.activeInterventionBookings.loading;
  },

  historyBookingsLoading(state) {
    return state.historyBookings.loading;
  },
  historyInterventionBookingsLoading(state) {
    return state.historyInterventionBookings.loading;
  },

  activeBookingsMapped(state) {
    return state.activeBookings.bookings.map(mapBooking);
  },
  activeInterventionBookingsMapped(state) {
    return state.activeInterventionBookings.bookings.map(mapBooking);
  },

  requestBookingsMapped(state) {
    const bookings = state.requestBookings.bookings.map(booking => {
      // Add Vehicle inside Location
      const locationUUID = {
        uuid: `location_${booking.uuid}`,
        original_uuid: booking.location.uuid,
      };
      const veh = { ...booking.vehicle };
      const loc = { ...booking.location, ...locationUUID };
      loc.vehicle = veh;

      // location START ?
      // location END ?
      const newBookingObject = {

        ...booking,
        location: { ...loc },
      };

      return newBookingObject;
    });

    return bookings;
  },

  hasActivePrebookings(state) {
    return size(state.requestBookings.bookings);
  },

  activeAndRequestBookingsMapped(state) {
    const bookings = state.activeAndRequestBookings.bookings.map(booking => {
      // Add Vehicle inside Location
      const locationUUID = {
        uuid: `location_${booking.uuid}`,
        original_uuid: booking.location.uuid,
      };
      const veh = { ...booking.vehicle };
      const loc = { ...booking.location, ...locationUUID };
      loc.vehicle = veh;

      const newBookingObject = {

        ...booking,
        location: { ...loc },
      };

      return newBookingObject;
    });

    return bookings;
  },

  historyBookingsMapped(state) {
    return state.historyBookings.bookings;
  },
  historyInterventionBookingsMapped(state) {
    return state.historyInterventionBookings.bookings;
  },

  activeBookingsMappedPagination(state) {
    return state.activeBookings.pagination || {};
  },
  activeInterventionBookingsMappedPagination(state) {
    return state.activeInterventionBookings.pagination || {};
  },

  historyBookingsMappedPagination(state) {
    return state.historyBookings.pagination || {};
  },
  historyInterventionBookingsMappedPagination(state) {
    return state.historyInterventionBookings.pagination || {};
  },

  createBookingRequestData(state) {
    return () => {
      if (state.started === false) {
        return null;
      }

      const res = state.reservation;

      const mandatories = {
        user_uuid: res.user.uuid,
        // Behalf employee is TRUE
        // when the booking is making from business profile and
        // is company admin.
        // We inject in driver the Company admin data or Employee data.
        user_profile_uuid: res.canUseBehalfEmployee
          ? res.driver.business_profile_uuid
          : res.profile.uuid,
        booking_type: res.booking_type,
        vehicle_uuid: res.vehicle.uuid,
        location_uuid: res.location.uuid,
        start: res.start,
        end: res.end,
        insurance_uuid: res.insurance.uuid,
      };

      if (res.comment) {
        mandatories.comment = res.comment;
      }

      return mandatories;
    };
  },

  createRideShareRequestData(state) {
    return (bookingUUID, { hasOutGoingTrip, hasReturnTrip }) => {
      const { reservation } = state;

      return {
        // Behalf employee is TRUE
        // when the booking is making from business profile and
        // is company admin.
        // We inject in driver the Company admin data or Employee data.
        user_uuid: reservation.canUseBehalfEmployee
          ? get(reservation, 'driver.uuid')
          : get(reservation, 'user.uuid'),
        booking_uuid: bookingUUID,
        ...hasOutGoingTrip && { outbound_available_seats: get(reservation, 'carpooling.outboundSeats') },
        ...hasOutGoingTrip && { outbound_destination_address: get(reservation, 'carpooling.outboundAddress') },
        ...hasReturnTrip && { return_start: get(reservation, 'carpooling.returnStart') },
        ...hasReturnTrip && { return_available_seats: get(reservation, 'carpooling.returnSeats') },
        ...hasReturnTrip && { return_origin_address: get(reservation, 'carpooling.returnAddress') },
      };
    };
  },

  newBookingHash() {
    return () => Math.random().toString(36).substr(2);
  },

  getBookingEncoded(state) {
    return (extra = {}) => (JSON.stringify({
      reservation: state.reservation,
      bookingSummary: state.bookingSummary,
      started: state.started,
      ...extra,
    }));
  },

  getRegularBookingsNumber(state) {
    const carsharingBookings = filter(get(state, 'activeAndRequestBookings.bookings'),
      booking => isRegular(get(booking, 'cs_booking_use_case.booking_type')));
    return size(carsharingBookings);
  },
  getInterventionBookingsNumber(state) {
    const interventions = filter(get(state, 'activeInterventionBookings.bookings'),
      booking => isIntervention(get(booking, 'cs_booking_use_case.booking_type')));
    return size(interventions);
  },
  availabilityTime(state) {
    return {
      start_availability: get(state, 'reservation.start_availability'),
      end_availability: get(state, 'reservation.end_availability'),
    };
  },
  isOutsideWorkingHours(state) {
    return get(state, 'bookingSummary.booking.outside_working_hours', false);
  },
  availablePurposes(state) {
    return get(state, 'logbook.availablePurposes', []);
  },
  reservation(state) {
    return get(state, 'reservation');
  },
  bookingSummary(state) {
    return get(state, 'bookingSummary');
  },
  bookedTime(_state, getters) {
    return {
      start: get(getters.reservation, 'start'),
      end: get(getters.reservation, 'end'),
    };
  },
  bookingUserUuid(_, getters) {
    return get(getters.reservation, 'user.uuid');
  },
  bookingVehicle(_, getters) {
    return get(getters.reservation, 'vehicle');
  },
  bookingLocation(_, getters) {
    return get(getters.reservation, 'location');
  },
  bookingDestinationLocation(_, getters) {
    return get(getters.reservation, 'dropOffLocation');
  },
  bookingLocationUuid(_, getters) {
    return get(getters.bookingLocation, 'uuid');
  },
  bookingDestinationLocationUuid(_, getters) {
    return get(getters.bookingDestinationLocation, 'uuid');
  },
  bookingInsurance(_, getters) {
    return get(getters.bookingSummary, 'insurance');
  },
  bookingSummaryTotalPrice(_, getters) {
    return get(getters.bookingSummary, 'price.total_raw');
  },
  isBookingLocationOpen(_, getters) {
    return get(getters.bookingLocation, 'isOpen');
  },
  isPrebooking(_, getters) {
    return get(getters.reservation, 'isPreBooking');
  },
  reservationOperator(_, getters) {
    return get(getters.reservation, 'operator');
  },
  bookingDriver(_, getters) {
    return get(getters.reservation, 'driver');
  },
  driverProfileUuid(_, getters) {
    return get(getters.reservation, 'profile.uuid');
  },
  driverBookingOnBehalfProfileUuid(_, getters) {
    return get(getters.bookingDriver, 'business_profile_uuid');
  },
  bookingOperatorUuid(_, getters) {
    return get(getters.reservationOperator, 'uuid');
  },
  bookingVehicleCategory(_, getters) {
    return get(getters.reservation, 'vehicleCategory');
  },
  bookingVehicleCategoryUuid(_, getters) {
    return get(getters.bookingVehicleCategory, 'uuid');
  },
  isBookingOnBehalf(_, getters) {
    return get(getters.reservation, 'canUseBehalfEmployee') && get(getters.reservation, 'isBookingBehalfEmployee');
  },
  bookingPeriods(_, getters) {
    return get(getters.reservation, 'periods');
  },
};
