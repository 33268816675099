import getInitialState from './_state';

export const MUTATIONS = {

  SET_START_DATETIME: 'SET_START_DATETIME',

  SET_END_DATETIME: 'SET_END_DATETIME',

  SET_DATETIME: 'SET_DATETIME',

  RESET_STATE: 'RESET_STATE',
};

export default {

  [MUTATIONS.SET_START_DATETIME](state, start) {
    return Object.assign(state, {
      start,
    });
  },

  [MUTATIONS.SET_END_DATETIME](state, end) {
    return Object.assign(state, {
      end,
    });
  },

  [MUTATIONS.SET_DATETIME](state, { id, datetime }) {
    return Object.assign(state.datetime, {
      [id]: datetime,
    });
  },

  [MUTATIONS.RESET_STATE](state) {
    return Object.assign(state, getInitialState());
  },
};
