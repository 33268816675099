import getInitialState from './_state';

export const MUTATIONS = {
  SET_COMPANY_DATA: 'SET_COMPANY_DATA',

  RESET_STATE: 'RESET_STATE',
};

export default {
  [MUTATIONS.SET_COMPANY_DATA](state, data) {
    return Object.assign(state, {
      data,
    });
  },

  [MUTATIONS.RESET_STATE](state) {
    return Object.assign(state, getInitialState());
  },
};
