import { TYPE } from './_mutations';

export default {
  setServiceProvider(context, { service, value }) {
    if (service) {
      if (value) {
        context.commit(TYPE.BIND_SERVICE_PROVIDER, { service, value });
      } else {
        context.commit(TYPE.RESET_STATE, service);
      }
    }
  },

  setPaymentMethodSummaryServiceProvider(context, value) {
    if (value) {
      context.commit(TYPE.BIND_SERVICE_PROVIDER, {
        service: 'paymentMethodSummary',
        value,
      });
    } else {
      context.commit(TYPE.RESET_STATE, 'paymentMethodSummary');
    }
  },

  setTariffServiceProvider(context, value) {
    if (value) {
      context.commit(TYPE.BIND_SERVICE_PROVIDER, {
        service: 'tariffs',
        value,
      });
    } else {
      context.commit(TYPE.RESET_STATE, 'tariffs');
    }
  },

  resetServiceProvider(context, serviceProvider) {
    context.commit(TYPE.RESET_STATE, serviceProvider);
  },
};
