import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const User = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  NAMESPACE: 'Refactor/SignUp/User',
};
export default User;
