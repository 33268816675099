import { PaymentMethod } from '@Shared/legacy/PaymentMethod';
import { TYPE } from './_mutations';

export default {
  setCompanyAdminPaymentMethodCollection(context, paymentMethodListResponse) {
    const paymentMethodCollection = paymentMethodListResponse.map(item => {
      // If paymentMethodListResponse is an array of PaymentMethods
      // We have not redeclare, we can return this item
      if (item.constructor === PaymentMethod) {
        return item;
      }

      return new PaymentMethod({
        uuid: item.uuid,
        type: item.type,
        internal: item.internal,
        number: item.number,
        expiry: item.expiry,
        method: item.method,
        holder: item.holder,
        active: item.active,
        default: item.default,
      });
    });

    context.commit(TYPE.SET_COMPANY_ADMIN_PAYMENT_METHOD_COLLECTION, paymentMethodCollection);
  },

  resetCompanyAdminPaymentMethodStore(context) {
    context.commit(TYPE.RESET_STATE);
  },
};
