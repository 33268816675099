import Api from '@/vue/api/backoffice';
import { TYPE } from './_mutations';

export default {
  contractTariff(context, { tariffUUID, periodicity }) {
    // Get current profile data from other Store Module
    const currentProfile = context.rootGetters['Profile/getCurrentProfile'];

    // Current profile should exist
    if (!currentProfile) {
      throw new Error('Current profile does not exist');
    }

    return Api.pricing.contractTariff(
      tariffUUID,
      {
        periodicity,
        profile: currentProfile.getUUID(),
      },
    );
  },

  cancelSubscriptionTariff(context, { tariffUUID }) {
    return Api.pricing.cancelTariffSubscription(
      tariffUUID,
    );
  },

  resetTariff(context) {
    context.commit(TYPE.RESET_STATE);
  },
};
