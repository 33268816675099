import BookingType from '@Bookings/legacy/BookingType';
import { TYPE } from './_mutations';

export default {
  /**
   *
   * @param context : Vue context
   * @param arr : Array of data
   */
  setBookingTypeCollection(context, arr = []) {
    // Transform data in a Handler/BookingType object
    const bookingTypeCollection = arr.map(data => new BookingType(data));

    // Keep this collection (array of BookingType) in Store
    context.commit(TYPE.SET_BOOKING_TYPE_COLLECTION, bookingTypeCollection);
  },

  /**
   *
   * @param context
   * @param bookingType : object of class Handlers/BookingType
   */
  setCurrentBookingType(context, bookingType = []) {
    context.commit(TYPE.SET_CURRENT_BOOKING_TYPE, bookingType);
  },

  resetBookingTypeStore(context) {
    context.commit(TYPE.RESET_STATE);
  },
};
