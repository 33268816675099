const state = () => ({
  currentStep: 'step1',
  notifyErrorCallback: null,
  lastStep: 1,
  linkAccount: {
    isLinkAccount: false,
    userData: {},
    idpUuid: null,
  },
  done: {
    step1: false,
    step2: false,
    step3: false,
  },
  info: {
    companySizes: [],
    countries: [],
    extraFields: [],
    legalForms: [],
    sectorEntities: [],
    titles: [],
    csOperator: null,
    legalDocuments: [],
    isEmailValid: false,
  },
  data: {

    // STEP 1
    companyEmail: '', // Only when user is creating his business account from scratch
    password: '', // Only when user is creating his business account from scratch
    title: '', // Only when user is creating his business account from scratch
    firstName: '', // Only when user is creating his business account from scratch
    lastName: '', // Only when user is creating his business account from scratch
    birthDate: '', // Only when user is creating his business account from scratch
    phoneNumber: '', // Only when user is creating his business account from scratch
    companyName: '',
    serviceCountryCode: '',
    vatNumber: '',
    taxNumber: '',
    workEmail: '', // When user is linking his personal account
    workPhoneNumber: '', // When user is linking his personal account
    extraDocuments: {
      italianPassport: null,
      italianIdFront: null,
      italianIdBack: null,
      italianCertificate: null,
    },
    siretNumber: '',

    // STEP 2
    companyAddress: '',
    extraCompanyAddress: '',
    legalForm: '',
    sectorEntity: '',
    companySize: '',
    italianFiscalCode: '',

    // STEP 3
    adminFiscalNumber: '',
    fiscalCode: '',
    italyTermsAndConditions: false,
    legalDocumentsAccepted: [],
    marketingTerms: false,
  },
});

export const FULL_COMPANY_NAMESPACE = 'Refactor/SignUp/Company';

export default state;
