export const GETTERS = {
  getStartDateTime: 'getStartDateTime',
  getEndDateTime: 'getEndDateTime',
  getDateTime: 'getDateTime',
};

export default {

  [GETTERS.getStartDateTime](state) {
    return state.start;
  },

  [GETTERS.getEndDateTime](state) {
    return state.end;
  },

  [GETTERS.getDateTime](state) {
    return (id => state.datetime[id] || null);
  },

};
