import { MUTATIONS } from './_mutations';

export const ACTIONS = {
  setStartDateTime: 'setStartDateTime',
  setEndDateTime: 'setEndDateTime',
  setDateTime: 'setDateTime',
  resetState: 'resetDatetimeStore',
};

export default {

  [ACTIONS.setStartDateTime](context, datetime) {
    context.commit(MUTATIONS.SET_START_DATETIME, datetime);
  },

  [ACTIONS.setEndDateTime](context, datetime) {
    context.commit(MUTATIONS.SET_END_DATETIME, datetime);
  },

  // We can use it as: setDateTime('ID', moment())
  [ACTIONS.setDateTime](context, ...datetime) {
    const [id, datetimeValue] = datetime;
    context.commit(MUTATIONS.SET_DATETIME, {
      id,
      datetime: datetimeValue,
    });
  },

  [ACTIONS.resetState](context) {
    context.commit(MUTATIONS.RESET_STATE);
  },
};
