import getInitialState from './_state';

export const TYPE = {
  SET_PAYMENT_METHOD_LIST: 'SET_PAYMENT_METHOD_LIST',

  SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',

  RESET_STATE: 'RESET_STATE',
};

export default {
  [TYPE.SET_PAYMENT_METHOD_LIST](state, list) {
    return Object.assign(state, {
      list,
    });
  },

  [TYPE.SET_ERROR_MESSAGE](state, error) {
    return Object.assign(state, {
      error,
    });
  },

  [TYPE.RESET_STATE](state) {
    return Object.assign(state, getInitialState());
  },
};
