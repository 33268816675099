import getInitialState from './_state';

export const TYPE = {
  SET_CURRENT_TARIFF: 'SET_CURRENT_TARIFF',
};

export default {
  [TYPE.SET_CURRENT_TARIFF](state, currentTariff) {
    return Object.assign(state, {
      currentTariff,
    });
  },

  [TYPE.RESET_STATE](state) {
    return Object.assign(state, getInitialState());
  },
};
