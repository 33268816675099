import Company from '@Account/legacy/Company';
import { MUTATIONS } from './_mutations';

export const ACTIONS = {
  setCompanyData: 'setCompanyData',
  resetCompanyStore: 'resetCompanyStore',
};

export default {
  [ACTIONS.setCompanyData](context, data) {
    const company = new Company(data);

    context.commit(MUTATIONS.SET_COMPANY_DATA, company);
  },

  [ACTIONS.resetCompanyStore](context) {
    context.commit(MUTATIONS.RESET_STATE);
  },
};
