import { Tariff } from '@Tariffs/legacy/Tariff';

import { TYPE } from './_mutations';

export default {
  setCurrentTariff(context, data) {
    const tariff = new Tariff(data);

    context.commit(TYPE.SET_CURRENT_TARIFF, tariff);
  },
};
