const getState = () => ({
  locationsCollection: undefined,
  preferredLocations: {
    custom: {
      address_street: '',
    },
    home: {
      address_street: '',
    },
    work: {
      address_street: '',
    },
  },
});

export default getState;
