import getInitialState from './_state';

export const MUTATIONS = {
  SET_CURRENT_CS_OPERATOR: 'SET_CURRENT_CS_OPERATOR',

  SET_CS_OPERATOR_COLLECTION: 'SET_CS_OPERATOR_COLLECTION',

  ADD_CS_OPERATOR_IN_COLLECTION: 'ADD_CS_OPERATOR_IN_COLLECTION',

  SET_CS_OPERATOR_VISITED: 'SET_CS_OPERATOR_VISITED',

  RESET_STATE: 'RESET_STATE',
};

export default {
  [MUTATIONS.SET_CURRENT_CS_OPERATOR](state, currentOperatorConfig) {
    return Object.assign(state, {
      currentOperatorConfig,
    });
  },

  [MUTATIONS.SET_CS_OPERATOR_COLLECTION](state, operatorConfigCollection) {
    return Object.assign(state, {
      operatorConfigCollection,
    });
  },

  [MUTATIONS.ADD_CS_OPERATOR_IN_COLLECTION](state, CSOperator) {
    return Object.assign(state, {
      operatorConfigCollection: [...state.operatorConfigCollection, CSOperator],
    });
  },

  [MUTATIONS.SET_CS_OPERATOR_VISITED](state, csOperatorVisited) {
    return Object.assign(state, {
      csOperatorVisited,
    });
  },

  [MUTATIONS.RESET_STATE](state) {
    return Object.assign(state, getInitialState());
  },
};
