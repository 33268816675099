const getState = () => ({

  currentOperatorConfig: undefined,

  operatorConfigCollection: [],

  csOperatorVisited: undefined,

});

export default getState;
