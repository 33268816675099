export default {
  getPaymentMethodList(state) {
    return state.list;
  },

  getPaymentMethodByUUID(state) {
    if (!state.list) {
      return null;
    }

    return uuid => state.list.filter(paymentMethod => paymentMethod.getUUID() === uuid)[0];
  },

  getCurrentPaymentMethod(state, getters, rootState, rootGetters) {
    if (!state.list) {
      return null;
    }

    const currentProfile = rootGetters['Profile/getCurrentProfile'];

    if (!currentProfile) {
      throw new Error('Current profile does not exist');
    }

    const uuid = currentProfile.getUUID();

    return state.list.filter(paymentMethod => paymentMethod.isAssignedTo(uuid))[0];
  },

  getErrorMessage(state) {
    return state.error;
  },
};
