const getState = () => ({

  userData: undefined,

  geolocation: undefined,

  permissions: {},

  circleManagerPermissions: undefined,

  referralInfo: {},
});

export default getState;
