const getState = () => ({
  reservation: {
    isBookingBehalfEmployee: false,
    canUseBehalfEmployee: false,
    user: null,
    profile: null,
    driver: null, // Employee Index from Algolia or stub object with same properties
    vehicle: null,
    vehicleCategory: null,
    location: null,
    pickUpLocation: null,
    dropOffLocation: null,
    insurance: null,
    booking_type: null,
    start: null,
    end: null,
    start_availability: null,
    end_availability: null,
    companyCostAllocation: null,
    comment: null,
    useCarpooling: false,
    carpooling: {
      outboundAddress: null,
      outboundSeats: null,
      returnAddress: null,
      returnStart: null,
      returnSeats: null,
    },
    isPreBooking: false,
    operator: null,
    isPaymentConfirmed: false,
  },
  bookingSummary: null,
  activeBookings: {
    bookings: [],
    loading: true,
    pagination: null,
  },
  requestBookings: {
    bookings: [],
    loading: true,
    pagination: null,
  },
  activeAndRequestBookings: {
    bookings: [],
    loading: true,
    pagination: null,
  },
  historyBookings: {
    bookings: [],
    loading: true,
    pagination: null,
  },
  activeInterventionBookings: {
    bookings: [],
    loading: true,
    pagination: null,
  },
  historyInterventionBookings: {
    bookings: [],
    loading: true,
    pagination: null,
  },
  started: false,
  estimatedTripMileage: null,
});

export default getState;
