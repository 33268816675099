import ModalComponent from '@Shared/legacy/ModalComponent';
import { TYPE } from './_mutations';

export default {
  setComponent(context, { component, params }) {
    const modalComponent = new ModalComponent(component, params);

    context.commit(TYPE.ADD_MODAL_COMPONENT, modalComponent);
  },

  open(context) {
    $('body').addClass('no-overflow');
    context.commit(TYPE.SET_STATUS, true);
  },

  close(context) {
    $('body').removeClass('no-overflow');

    context.commit(TYPE.REMOVE_MODAL_COMPONENT);

    const stack = context.getters.getModalComponentStack;

    if (stack.length === 0) {
      context.commit(TYPE.SET_STATUS, false);
    }
  },

  resetModalStore(context) {
    context.commit(TYPE.RESET_STATE);
  },
};
